import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingButton from "../../components/LoadingButton";
import LoadingAnimation from "../../components/loading/LoadingAnimation";
import { useAIActionArea, useAIExperiment, useAIExperiments } from "../../domain/measure/detail";
import { translationKeys } from "../../translations/main-translations";
import { useMeasureContext } from "../MeasureContext";
import MeasureFullHeightTab from "./MeasureFullHeightTab";
import MeasureInfoCard from "./MeasureInfoCard";

const MeasureSuggestions = () => {
    const [rateLimitActive, setRateLimitActive] = useState(false);
    const measure = useMeasureContext();
    const measureId = measure.id;
    const actionAreaMutation = useAIActionArea();
    const useAIExperimentsQuery = useAIExperiments(measureId);
    const aiExperimentMutation = useAIExperiment();

    const { t: translate } = useTranslation();

    if (measureId == null) {
        return <LoadingAnimation />;
    }

    const fetchSuggestions = () => {
        actionAreaMutation.mutate(measureId);

        setRateLimitActive(true);
        setTimeout(() => {
            setRateLimitActive(false);
        }, 25000); // Limit to 25s for now (Backend 20s)
    };

    const runExperiment = (experiment: string) => {
        aiExperimentMutation.mutate({ measureId, experiment });

        setRateLimitActive(true);
        setTimeout(() => {
            setRateLimitActive(false);
        }, 25000); // Limit to 25s for now (Backend 20s)
    };

    return (
        <MeasureFullHeightTab>
            <Stack spacing={2}>
                <MeasureInfoCard severity="info" title="">
                    {translate(translationKeys.VDLANG_OPENAI_MEASURE_HINT)}
                </MeasureInfoCard>
                <Card sx={{ width: "100%" }}>
                    <CardHeader
                        title={translate(translationKeys.VDLANG_MEASURE_SUGGESTIONS_ACTION_AREA)}
                        subheader="This will suggest a new action area for this measure."
                        subheaderTypographyProps={{ variant: "body2" }}
                    />
                    <CardContent>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            disabled={rateLimitActive}
                            onClick={fetchSuggestions}
                            startIcon={<AutoFixHighIcon />}
                            isLoading={actionAreaMutation.isLoading || aiExperimentMutation.isLoading || rateLimitActive}
                        >
                            {translate(translationKeys.VDLANG_MEASURE_TABS_SUGGESTIONS)}
                        </LoadingButton>
                    </CardContent>
                    {actionAreaMutation.isSuccess ? (
                        <CardContent>
                            <Typography>{actionAreaMutation.data.message}</Typography>
                        </CardContent>
                    ) : null}
                </Card>
                {useAIExperimentsQuery.isSuccess
                    ? useAIExperimentsQuery.data.map((experiment) => (
                          <Card sx={{ width: "100%" }} key={experiment.experiment}>
                              <CardHeader
                                  title={experiment.title}
                                  subheader={experiment.description}
                                  subheaderTypographyProps={{ variant: "body2" }}
                              />
                              <CardContent>
                                  <LoadingButton
                                      variant="contained"
                                      color="primary"
                                      disabled={rateLimitActive}
                                      onClick={() => runExperiment(experiment.experiment)}
                                      startIcon={<AutoFixHighIcon />}
                                      isLoading={actionAreaMutation.isLoading || aiExperimentMutation.isLoading || rateLimitActive}
                                  >
                                      {translate(translationKeys.VDLANG_MEASURE_TABS_SUGGESTIONS)}
                                  </LoadingButton>
                              </CardContent>
                              {aiExperimentMutation.isSuccess ? (
                                  <CardContent>
                                      <Typography>{aiExperimentMutation.data.message}</Typography>
                                  </CardContent>
                              ) : null}
                          </Card>
                      ))
                    : null}
            </Stack>
        </MeasureFullHeightTab>
    );
};

export default React.memo(MeasureSuggestions);
